import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import Close from '@mui/icons-material/Close';
import '../assets/css/App.css';
import * as auth from '../modules/Auth';
import Modal from './modal/Modal';
import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/img/bell_logo.png';
import { SoundNameEnum } from '../enums/sound-name.enum';
import HowlerSound from '../modules/HowlerSound';
import * as DataProcessing from '../modules/DataProcessing';

function Header(props: any) {
  const navigate = useNavigate();

  // 모달창 열기/닫기 플래그
  const [modalOpen, setModalOpen] = useState(false);

  // 모달창 열기 이벤트
  const openModal = () => {
    setModalOpen(true);
  };

  // 모달창 닫기 이벤트
  const closeModal = () => {
    setModalOpen(false);
  };

  // 모달창 확인 이벤트
  const confirmModal = () => {
    setModalOpen(false);
    // TODO 변수명 이거 바꿔야함
    if (!props.isLogout) {
      for (const key of Object.keys(SoundNameEnum)) {
        HowlerSound.sndStop(key as SoundNameEnum);
      }
      navigate('/', { replace: true });
    } else {
      auth.Logout();
    }
    const patientSeq = sessionStorage.getItem('patient_seq') ?? '';
    DataProcessing.updateData(patientSeq, props.impressiveData);
  };

  return (
    <div>
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item xs={10}>
          <img src={logo} style={{ height: '20px' }} alt="" />
        </Grid>
        <Grid
          item
          xs={2}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <IconButton onClick={openModal} style={{ paddingRight: 0 }}>
            {props.isLogout ? (
              <LogoutIcon style={{ color: 'white' }} />
            ) : (
              <Close style={{ color: 'white' }} />
            )}
          </IconButton>
        </Grid>
      </Grid>
      <Modal
        open={modalOpen}
        close={closeModal}
        isCancel="true"
        confirm={confirmModal}
        header={props.isLogout ? '로그아웃' : '여기까지 할까요?'}
      >
        {/* Modal.js <main> {props.children} </main>에 내용이 입력된다. */}
        {props.isLogout ? (
          <Typography variant="body2">로그아웃 할까요?</Typography>
        ) : (
          <Typography variant="body2">
            최소 {props.session_length === 30 ? '10' : '5'}분 이상 사용하시는
            것을 권장드립니다. {props.session_length}
            분이 지나면 자동으로 종료됩니다.
          </Typography>
        )}
      </Modal>
    </div>
  );
}

export default Header;
