import React from 'react';
import '../assets/css/State.css';

const State = (props: any) => {
  const isPassive = props.isPassive;
  return (
    <div
      className="state"
      style={{ backgroundPosition: !isPassive ? '100% 0%' : '0% 0%' }}
    >
      <span
        style={{
          color: isPassive
            ? 'rgba(255, 255, 255, 0.2)'
            : 'rgba(255, 255, 255, 1)',
        }}
      >
        의식 모드
      </span>
      <span
        style={{
          color: isPassive
            ? 'rgba(255, 255, 255, 1)'
            : 'rgba(255, 255, 255, 0.2)',
        }}
      >
        무의식 모드
      </span>
    </div>
  );
};

export default State;
