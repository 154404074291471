import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Home from './pages/Home';
import Session from './pages/Session';
import SessionInfo from './pages/SessionInfo';
import QRCodeLogin from './pages/QRCodeLogin';
import ModeSelect from './pages/ModeSelect';

function App() {
  let isAuthorized = sessionStorage.getItem('isAuthorized');

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={isAuthorized ? <Home /> : <Login />} />
        <Route
          path="/qrcode"
          element={isAuthorized ? <Home /> : <QRCodeLogin />}
        />

        <Route
          path="/sessioninfo"
          element={isAuthorized ? <SessionInfo /> : <Login />}
        />
        <Route
          path="/session"
          element={isAuthorized ? <Session /> : <Login />}
        />
        <Route
          path="/modeselect"
          element={isAuthorized ? <ModeSelect /> : <Login />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
