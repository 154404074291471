// 음원 관련 모듈
import { Howl } from 'howler';
import { SoundNameEnum } from '../enums/sound-name.enum';

const activatedSounds: { [index: string]: Howl } = {};

export default class HowlerSound {
  // 음원 로딩여부 체크 함수
  static async isLoaded() {
    const loadingPromises = [];
    for (const key of Object.keys(SoundNameEnum)) {
      const promise = new Promise(async (resolve, reject) => {
        const importedSound = await import(
          `../assets/sound/${key.toLowerCase()}.mp3`
        );
        const breathSound = new Howl({
          src: [importedSound.default],
          loop: false,
          volume: 0.001,
          onloaderror: (soundId, error) => {
            console.log(error);
            reject(error);
          },
          onload: () => {
            activatedSounds[key] = breathSound;
            resolve(true);
          },
        });
      });
      loadingPromises.push(promise);
    }
    try {
      await Promise.all(loadingPromises);
      return true;
    } catch (error) {
      console.error('Error loading sounds:', error);
      return false;
    }
  }

  // 음원 재생 함수

  static sndPlay(soundNameEnum: SoundNameEnum | string, vol: number) {
    if (activatedSounds[soundNameEnum]) {
      activatedSounds[soundNameEnum].volume(vol);
      activatedSounds[soundNameEnum].play();
    } else {
      console.log("Can't Play the sound");
    }
  }

  // 음원 정지 함수

  static sndStop(soundNameEnum: SoundNameEnum | string) {
    if (activatedSounds[soundNameEnum]) {
      activatedSounds[soundNameEnum].stop();
    } else {
      console.log("Can't Stop the sound");
    }
  }

  // 음원 fadeout 함수
  // audio 객체의 volume 범위 : 0 to 1
  static sndFade(
    soundNameEnum: SoundNameEnum | string,
    vol: number,
    length: number
  ) {
    if (activatedSounds[soundNameEnum]) {
      activatedSounds[soundNameEnum].fade(vol, 0, length);
    } else {
      console.log("Can't fade the sound");
    }
  }
}
