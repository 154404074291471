import React, { useState } from 'react';
import * as auth from '../modules/Auth';
import logo from '../assets/img/bell_logo.png';
import {
  Alert,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import '../assets/css/App.css';

const useStyles = {
  input: {
    '& label': {
      color: 'white',
      '&.Mui-focused': {
        color: 'white',
      },
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'white',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
  },
  button: {
    background: 'white',
    color: '#27234C',
    boxShadow: 'none',
    borderRadius: 30,
    height: 48,
  },
};

export default function Login() {
  const [id, setId] = useState('');
  const [birth, setBirth] = useState('');
  const [agree, setAgree] = useState(true);
  const [error, setError] = useState(<></>);
  function submit() {
    // resultcode 정의
    // 1 : 성공
    // 2 : 환자 데이터 불일치
    // 3 : 접속일자 불일치
    // 4 : 기타
    let data = auth.Login(id, birth);
    data.then((res) => {
      try {
        switch (res.resultcode) {
          case 1:
            sessionStorage.setItem('isAuthorized', 'true');
            sessionStorage.setItem('patient_seq', res.patient_seq);
            sessionStorage.setItem('patient_name', res.name);
            sessionStorage.setItem('session_length', res.session_length);
            sessionStorage.setItem('access_start', res.access_start);
            sessionStorage.setItem('access_end', res.access_end);
            sessionStorage.setItem('exc_day', res.exc_day);
            sessionStorage.setItem('op_from', res.op_from);
            window.location.reload();
            break;
          case 2:
            setError(
              <Alert severity="error">
                ID와 비밀번호를 다시 한번 확인해 주세요.
              </Alert>
            );
            break;
          case 3:
            setError(<Alert severity="error">접속가능 일자가 아닙니다.</Alert>);
            break;
          default:
            setError(
              <Alert severity="error">
                현재 서버에 접속할 수 없습니다. 관리자에게 문의해 주세요.
              </Alert>
            );
            break;
        }
      } catch {
        setError(
          <Alert severity="error">
            현재 서버에 접속할 수 없습니다. 관리자에게 문의해 주세요.
          </Alert>
        );
      }
    });
  }

  // 입력데이터 검증 및 로그인 버튼 활성화
  function checkAgree() {
    if (!agree) {
      setError(<></>);
      setAgree(true);
    } else {
      setAgree(false);
    }
  }

  return (
    <div className="App">
      <div className="login-logo">
        <img src={logo} alt="" />
      </div>
      <p></p>
      <Stack sx={{ width: '100%', textAlign: 'left' }} spacing={2}>
        <TextField
          sx={useStyles.input}
          label="ID"
          value={id}
          onChange={(e) => setId(e.target.value)}
          InputProps={{ style: { color: 'white' } }}
        />
        <p></p>
        <TextField
          sx={useStyles.input}
          type="password"
          label="비밀번호(8자리)"
          value={birth}
          onChange={(e) => setBirth(e.target.value)}
          InputProps={{ style: { color: 'white' } }}
        />
        <p></p>
        <FormControlLabel
          control={
            <Checkbox
              checked={agree}
              style={{ color: 'white' }}
              onChange={checkAgree}
            />
          }
          label={
            <Typography style={{ color: 'white' }}>이용약관동의</Typography>
          }
        />
        <Typography
          variant="caption"
          style={{ color: 'white', marginLeft: 30 }}
        >
          이름을 포함한 식별정보를 수집하지 않습니다. 모든 데이터는 익명화되며,
          연구목적으로 활용될 수 있습니다. 로그인 시 이용약관 및 개인정보
          처리방침에 동의하신 것으로 간주합니다.
        </Typography>
        <p></p>
        {error}
      </Stack>
      <Stack sx={{ position: 'fixed', bottom: '6%', width: '80%' }} spacing={2}>
        <Button
          sx={useStyles.button}
          variant="contained"
          onClick={submit}
          disabled={!agree}
        >
          로그인
        </Button>
      </Stack>
    </div>
  );
}
