import React, { Fragment } from 'react';
import '../../assets/css/Modal.css';
import ModalContainer from './ModalContainer';

const Modal = (props: any) => {
  // 열기, 닫기, 확인, 모달 헤더 텍스트를 부모로부터 받아옴
  const { open, close, header, confirm, isCancel } = props;

  return (
    // 모달이 열릴때 openModal 클래스가 생성된다.
    <ModalContainer>
      <div className={open ? 'openModal modal' : 'modal'}>
        {open ? (
          <section>
            <header>
              {header}
              <button className="close" onClick={close}>
                &times;
              </button>
            </header>
            <main>{props.children}</main>
            <footer>
              {isCancel === 'true' ? (
                <button className="close" onClick={close}>
                  취소
                </button>
              ) : (
                <Fragment></Fragment>
              )}
              <button className="close" onClick={confirm}>
                확인
              </button>
            </footer>
          </section>
        ) : null}
      </div>
    </ModalContainer>
  );
};

export default Modal;
