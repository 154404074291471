import axios from 'axios';
import { GetLoginDataTypes } from './types/get-login-data.types';
import { GetInfoDataTypes } from './types/get-info-data.types';
// 인증 관련 모듈
// 로그인
// input : name(string), birth(string)
// resultcode 정의
// 1 : 성공
// 2 : 환자 데이터 불일치
// 3 : 접속일자 불일치
// 4 : 기타
function getLoginData(getLoginDataTypes: GetLoginDataTypes) {
  return axios
    .post('https://www.belltherapeutics.net/api/login', getLoginDataTypes)
    .then((res) => {
      console.log(res);
      return res.data;
    })
    .catch((err) => {});
}

function getQRCodeLoginData(getQRCodeLoginDataTypes: { id: string }) {
  return axios
    .post(
      'https://www.belltherapeutics.net/api/qr-code/login',
      getQRCodeLoginDataTypes
    )
    .then((res) => res.data)
    .catch((err) => {});
}

function getInfoData(getInfoDataTypes: GetInfoDataTypes) {
  return axios
    .post('https://www.belltherapeutics.net/api/info', getInfoDataTypes)
    .then((res) => res.data)
    .catch((err) => {
      console.error(err);
    });
}

export async function Login(id: string, birth: string) {
  let data: GetLoginDataTypes = { id, birth };
  const data1 = await getLoginData(data);
  return data1;
}

export async function QRCodeLogin(id: string) {
  let data = { id };
  const data1 = await getQRCodeLoginData(data);
  return data1;
}

export async function GetInfo(id: string) {
  let data: GetInfoDataTypes = { id };
  const data1 = await getInfoData(data);
  return data1;
}

// 로그아웃
export function Logout() {
  sessionStorage.clear();
  window.location.replace('/');
}
