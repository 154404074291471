import React, { useEffect, useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import '../assets/css/App.css';
import * as auth from '../modules/Auth';
import Header from '../component/Header';
import ModalBottom from '../component/modal/ModalBottom';

function Home() {
  const [firstModal, setFirstModal] = useState(false);

  const closeModal = (e: any) => {
    e.stopPropagation();
    setFirstModal(false);
  };

  useEffect(() => {
    getInfo();
  });

  function getInfo() {
    const patientSeq = sessionStorage.getItem('patient_seq');
    if (!patientSeq) {
      console.log('Error: No patient id');
      return;
    }
    const data = auth.GetInfo(patientSeq);
    data.then((res) => {
      switch (res.resultcode) {
        case 1:
          sessionStorage.setItem('today_exc_cnt', res.today_exc_cnt);
          sessionStorage.setItem('latest_avg', res.latest_avg);
          sessionStorage.setItem('pre_latest_avg', res.pre_latest_avg);
          break;
        default:
          break;
      }
    });
  }

  function start() {
    setFirstModal(true);
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Header isLogout={true} />
      <div
        style={{
          width: '100%',
          height: '75%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h4"
          style={{
            fontWeight: 'bold',
            color: 'white',
            marginBottom: '1%',
          }}
        >
          안녕하세요,
        </Typography>
        <Typography variant="h6" style={{ color: 'white', marginBottom: '1%' }}>
          음악과 함께 호흡하고
        </Typography>
        <Typography variant="h6" style={{ color: 'white', marginBottom: '1%' }}>
          깊은 휴식과 수면을 경험하세요
        </Typography>
        <Stack
          sx={{
            position: 'fixed',
            bottom: '6%',
            width: '80%',
            height: '80px',
          }}
          spacing={2}
        >
          <Button
            style={{
              height: '100%',
              fontSize: '6vw',
              fontWeight: 'bold',
              background: 'white',
              color: '#27234C',
              boxShadow: 'none',
              borderRadius: 30,
            }}
            variant="contained"
            onClick={start}
          >
            소닉 테라피 시작하기
          </Button>
        </Stack>
        <ModalBottom
          open={firstModal}
          close={closeModal}
          header="이렇게 사용하세요"
        >
          <ul style={{ marginLeft: '-21px', fontSize: '1em' }}>
            <li style={{ marginBottom: '4%' }}>
              화면을 지긋이 누르며 숨을 들이마십니다.
            </li>
            <li style={{ marginBottom: '4%' }}>손을 떼면서 숨을 내쉽니다.</li>
            <li style={{ marginBottom: '4%' }}>
              반복하면서 점점 깊게 호흡합니다.
              <div style={{ fontSize: '0.75rem', opacity: 0.75 }}>
                당신에게 맞춰진 음악은 깊고 길게 호흡할 수 있도록 도와줍니다.
              </div>
              <div style={{ fontSize: '0.75rem', opacity: 0.75 }}>
                의식 모드는 1~3분 사용을 권장합니다.
              </div>
            </li>
            <li style={{ marginBottom: '4%' }}>
              호흡의 속도가 안정되었다면 짧게 한번만 터치합니다.
              <div style={{ fontSize: '0.75rem', opacity: 0.75 }}>
                무의식모드에서는 호흡 패턴에 최적화된 사운드가 자동으로
                만들어집니다.
              </div>
              <div style={{ fontSize: '0.75rem', opacity: 0.75 }}>
                가장 편한 자세로 음악과 호흡에만 집중해보세요.
              </div>
            </li>
            <li style={{ marginBottom: '4%' }}>
              무의식 모드에서 호흡이 불편해진 경우, 언제든지 화면을 터치하여
              호흡속도를 조절할 수 있습니다.
            </li>
          </ul>
        </ModalBottom>
      </div>
    </div>
  );
}

export default Home;
