import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import '../assets/css/App.css';
import Header from '../component/Header';

function ModeSelect() {
  let navigate = useNavigate();

  const startProgram = (mode: number, e: any) => {
    e.preventDefault();
    sessionStorage.setItem('isPractice', 'N');
    switch (mode) {
      case 1:
        sessionStorage.setItem('session_level', '1');
        sessionStorage.setItem('session_length', '5');
        break;
      case 2:
        sessionStorage.setItem('session_level', '2');
        sessionStorage.setItem('session_length', '10');
        break;
      case 3:
        sessionStorage.setItem('session_level', '3');
        sessionStorage.setItem('session_length', '30');
        break;
      default:
        sessionStorage.setItem('session_length', '0');
        break;
    }

    navigate('/sessioninfo', { replace: true });
  };

  return (
    <div>
      <Header />
      <div>
        <Typography
          variant="h5"
          style={{
            fontWeight: 'bold',
            color: 'white',
            marginTop: '10%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          어떤 테라피를
        </Typography>
        <Typography
          variant="h5"
          style={{
            fontWeight: 'bold',
            color: 'white',
            marginBottom: '10%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          시작할까요?
        </Typography>
        <p></p>
        <Stack
          sx={{
            textAlign: 'center',
            width: '80%',
            px: '10%',
            py: '8%',
            border: '1px solid #ffffff',
            borderRadius: 35,
            marginBottom: '5%',
          }}
          onClick={(e) => {
            startProgram(1, e);
          }}
        >
          <Typography
            variant="body1"
            style={{ fontWeight: 'bold', color: 'white' }}
          >
            산소로 온몸을 개운하게 &nbsp;
            <Stack fontWeight="fontWeightMedium" fontSize={25} display="inline">
              {' '}
              5분{' '}
            </Stack>
          </Typography>
        </Stack>
        <Stack
          sx={{
            textAlign: 'center',
            width: '80%',
            px: '10%',
            py: '8%',
            border: '1px solid #ffffff',
            borderRadius: 35,
            marginBottom: '5%',
          }}
          onClick={(e) => {
            startProgram(2, e);
          }}
        >
          <Typography
            variant="body1"
            style={{ fontWeight: 'bold', color: 'white' }}
          >
            천천히 생각을 정리하는 &nbsp;
            <Stack fontWeight="fontWeightMedium" fontSize={25} display="inline">
              {' '}
              10분{' '}
            </Stack>
          </Typography>
        </Stack>
        <Stack
          sx={{
            textAlign: 'center',
            width: '80%',
            px: '10%',
            py: '8%',
            border: '1px solid #ffffff',
            borderRadius: 35,
            marginBottom: '5%',
          }}
          onClick={(e) => {
            startProgram(3, e);
          }}
        >
          <Typography
            variant="body1"
            style={{ fontWeight: 'bold', color: 'white' }}
          >
            깊고 편안한 수면을 위한 &nbsp;
            <Stack fontWeight="fontWeightMedium" fontSize={25} display="inline">
              {' '}
              30분{' '}
            </Stack>
          </Typography>
        </Stack>
      </div>
    </div>
  );
}

export default ModeSelect;
