export enum SoundNameEnum {
  INHALE1_5MIN = 'INHALE1_5MIN',
  INHALE2_5MIN = 'INHALE2_5MIN',
  INHALE3_5MIN = 'INHALE3_5MIN',
  EXHALE1_5MIN = 'EXHALE1_5MIN',
  EXHALE2_5MIN = 'EXHALE2_5MIN',
  EXHALE3_5MIN = 'EXHALE3_5MIN',
  INHALE1_10MIN = 'INHALE1_10MIN',
  INHALE2_10MIN = 'INHALE2_10MIN',
  INHALE3_10MIN = 'INHALE3_10MIN',
  EXHALE1_10MIN = 'EXHALE1_10MIN',
  EXHALE2_10MIN = 'EXHALE2_10MIN',
  EXHALE3_10MIN = 'EXHALE3_10MIN',
  INHALE1_30MIN = 'INHALE1_30MIN',
  INHALE2_30MIN = 'INHALE2_30MIN',
  INHALE3_30MIN = 'INHALE3_30MIN',
  EXHALE1_30MIN = 'EXHALE1_30MIN',
  EXHALE2_30MIN = 'EXHALE2_30MIN',
  EXHALE3_30MIN = 'EXHALE3_30MIN',
}
