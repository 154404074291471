import React from 'react';
import '../../assets/css/ModalBottom.css';
import ModalContainer from './ModalContainer';
import { useNavigate } from 'react-router-dom';

const ModalBottom = (props: any) => {
  // 열기, 닫기, 확인, 모달 헤더 텍스트를 부모로부터 받아옴
  const { open, close, header, finished } = props;

  let navigate = useNavigate();

  const startProgram = (e: any) => {
    e.preventDefault();
    let patient_seq = sessionStorage.getItem('patient_seq');
    if (patient_seq === null) {
      return;
    }

    navigate('/modeselect', { replace: true });
  };

  const finishSession = (e: any) => {
    e.preventDefault();
    navigate('/', { replace: true });
  };

  return (
    // 모달이 열릴때 openModal 클래스가 생성된다.
    <ModalContainer>
      <div className={open ? 'openModal modalBottom' : 'modalBottom'}>
        {open ? (
          <section>
            <header>
              {header}
              <button className="close" onClick={close}>
                &times;
              </button>
            </header>
            <main
              style={{
                paddingBottom: '0',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              {props.children}
            </main>
            <footer>
              <button
                onClick={(e) => {
                  if (finished) {
                    finishSession(e);
                  } else {
                    startProgram(e);
                  }
                }}
              >
                {finished ? '확인' : '시작하기'}
              </button>
            </footer>
          </section>
        ) : null}
      </div>
    </ModalContainer>
  );
};

export default ModalBottom;
