import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import '../assets/css/App.css';
import Typography from '@mui/material/Typography';
import Header from '../component/Header';
import * as DataProcessing from '../modules/DataProcessing';
import { useNavigate } from 'react-router-dom';
import State from '../component/State';
import Interaction from '../component/Interaction';
import { parse } from 'papaparse';
import csv5min from '../assets/csv/5min.csv';
import csv10min from '../assets/csv/10min.csv';
import csv30min from '../assets/csv/30min.csv';
import ModalBottom from '../component/modal/ModalBottom';
import { AJsonTypes } from '../modules/types/aJson.types';
import NoSleep from '@uriopass/nosleep.js';
import { SoundNameEnum } from '../enums/sound-name.enum';
import { ModeEnum } from '../enums/mode.enum';
import HowlerSound from '../modules/HowlerSound';

const nosleep: any = new NoSleep();

const StyledTypography = styled(Typography)`
  color: white;
  margin-bottom: 24;
  white-space: pre-line;
`;

const EndAlertText = styled(Typography)`
  display: flex;
  justify-content: center;
`;

const BeforeFirstTouch = styled(Typography)`
  color: white;
  white-space: pre-line;
  width: 100%;
  height: 100%;
  margin-top: 120px;
  text-align: center;
`;

export default function Session() {
  // 음원 볼륨 컨트롤용 csv reading
  const csvData = useRef<any>([]);
  const file = useRef<any>();
  const session_length = parseInt(
    sessionStorage.getItem('session_length') ?? '30'
  );
  useEffect(() => {
    switch (session_length) {
      case 5:
        file.current = csv5min;
        break;
      case 10:
        file.current = csv10min;
        break;
      case 30:
        file.current = csv30min;
        break;
      default:
        break;
    }
    parse(file.current, {
      header: true,
      download: true,
      complete: (results: any) => {
        csvData.current = results.data;
      },
    });
  }, [session_length]);

  // session 단계를 n이라 하면 각 단계별 sound set의 첫번째 sound idx를 등차수열로 배치 (6n-2)

  const breathCount = useRef(0);
  const inhale1vol = useRef(0);
  const inhale2vol = useRef(0);
  const inhale3vol = useRef(0);
  const exhale1vol = useRef(0);
  const exhale2vol = useRef(0);
  const exhale3vol = useRef(0);
  const [mainTextInSession, setMainTextInSession] = useState(
    '화면을 길게 누르면서\n 숨을 깊게 들이마십니다'
  );
  const impressiveData = useRef<AJsonTypes[]>([]);
  const patientSeq = sessionStorage.getItem('patient_seq') ?? '';
  const navigate = useNavigate();
  const [isActivatedInteraction, setIsActivatedInteraction] = useState(false);
  const [firstTouch, setFirstTouch] = useState(true);
  const [firstClick, setFirstClick] = useState(true);
  const [isStarted, setIsStarted] = useState(false);

  // 1. session 총 길이가 지났을 경우에 발생되는 이벤트 (1분마다 체크)
  // 2. 들숨/날숨의 2사이클 이후부터 counter start
  // const session_length = 30; // 30min
  const practice_cycle_num = 2; // 연습 2 사이클 이후부터 counter start

  const time = useRef(0);
  const [timer, setTimer] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    let intervalId: any;
    if (isRunning) {
      intervalId = setInterval(() => {
        time.current += 1;
        setTimer(timer + 1);
      }, 1000);
    }
    // if (timer !== 0 && timer % 60 === 0) {
    //   DataProcessing.updateData(patientSeq, impressiveData.current);
    // }
    if (timer >= 60 * session_length) {
      time.current = 0;
      setTimer(0);
      setSessionEndAlertModal(true);
      nosleep.disable();
      setFirstTouch(true);
      setFirstClick(true);
      // DataProcessing.updateData(patientSeq, impressiveData.current);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [time, isRunning, timer, session_length]); //, patientSeq]);

  // 브라우저 닫기, 새로고침, 뒤로가기 방지
  const preventClose = useCallback(
    (e: any) => {
      e.preventDefault();
      console.log(e);
      const confirmationMessage =
        'Are you sure you want to refresh? Your unsaved changes may be lost.';
      if (!patientSeq) {
        return;
      }

      // DataProcessing.updateData(patientSeq, impressiveData.current);
      e.returnValue = confirmationMessage;
      return confirmationMessage;
    },
    [patientSeq]
  );

  useEffect(() => {
    (() => {
      window.addEventListener('beforeunload', preventClose);
      window.addEventListener('popstate', preventClose);
    })();
    return () => {
      window.removeEventListener('beforeunload', preventClose);
      window.removeEventListener('popstate', preventClose);
    };
  }, [preventClose]);

  // passive session
  const [unconsciousMode, setUnconsciousMode] = useState(false); // passive 전환 플래그
  const [inhaleUnconsciousMode, setInhaleUnconsciousMode] = useState(false); // passive 세션 진행 시의 들숨/날숨 전환 플래그
  const firstIn = useRef(false); // passive 세션 전환 시의 최초 들숨 상태값
  const firstEx = useRef(false); // passive 세션 전환 시의 최초 날숨 상태값
  const avgInhale = useRef(0); // 평균 들숨 시간
  const avgExhale = useRef(0); // 평균 날숨 시간

  // 모달창 열기/닫기 플래그
  const [sessionEndAlertModal, setSessionEndAlertModal] = useState(false);

  // 모달창 닫기 이벤트
  const closeModal = (e: any) => {
    e.stopPropagation();
    for (const key of Object.keys(SoundNameEnum)) {
      HowlerSound.sndStop(key as SoundNameEnum);
    }

    if (!patientSeq) {
      return;
    }

    DataProcessing.updateData(patientSeq, impressiveData.current);
    setSessionEndAlertModal(false);
    navigate('/', { replace: true });
  };

  const handleClick = async (e: any) => {
    setIsStarted(true);
    e.preventDefault();
    HowlerSound.sndPlay(`INHALE1_${String(session_length)}MIN`, 0);
    HowlerSound.sndPlay(`INHALE2_${String(session_length)}MIN`, 0);
    HowlerSound.sndPlay(`INHALE3_${String(session_length)}MIN`, 0);
    breathCount.current = 0;
    await DataProcessing.insertData(patientSeq);
  };

  // 눌렀을때 이벤트 (inhale)
  const handleTouchStart = async (e: any) => {
    e.preventDefault();
    if (firstTouch) {
      await nosleep.enable();
      setFirstTouch(false);
      // await DataProcessing.insertData(patientSeq);
    }
    await inhaleEvt(ModeEnum.CONSCIOUS);
  };

  // 뗐을때 이벤트 (exhale)
  const handleTouchEnd = async (e: any) => {
    e.preventDefault();
    const interval = DataProcessing.calcInterval(impressiveData.current); // ex -> in
    if (interval >= 1000) {
      breathCount.current += 1;
      if (!isRunning && breathCount.current >= practice_cycle_num) {
        time.current = 0;
        setIsRunning(true);
      }
    }
    await exhaleEvt(ModeEnum.CONSCIOUS);
  };

  const handleOnMouseDown = async (e: any) => {
    if (firstClick) {
      await nosleep.enable();
      setFirstClick(false);
      // await DataProcessing.insertData(patientSeq);
    }
    e.preventDefault();
    await inhaleEvt(ModeEnum.CONSCIOUS);
  };

  // 뗐을때 이벤트 (exhale)
  const handleOnMouseUp = (e: any) => {
    e.preventDefault();
    const interval = DataProcessing.calcInterval(impressiveData.current); // ex -> in
    if (interval >= 1000) {
      breathCount.current += 1;
      if (!isRunning && breathCount.current >= practice_cycle_num) {
        time.current = 0;
        setIsRunning(true);
      }
    }
    exhaleEvt(ModeEnum.CONSCIOUS);
  };

  // entering passive
  const unconsciousModeStart = () => {
    // 1. 기존 active에서의 들숨, 날숨의 평균값 계산
    // 2. passive session toggle on (항상 들숨부터 시작하게 설정)
    // 3. 클릭시 발생된 in/ex 데이터 삭제
    impressiveData.current.splice(-2, 2);
    avgInhale.current = DataProcessing.calculateAvg(impressiveData.current, 1);
    avgExhale.current = DataProcessing.calculateAvg(impressiveData.current, 2);
    setUnconsciousMode(true);
    setInhaleUnconsciousMode(false);
    firstIn.current = true;
    firstEx.current = true;
    setMainTextInSession(
      '이제, 손을 내려놓고\n음악과 함께 호흡하세요\n\n언제든 화면을 터치할 수 있어요'
    );
    DataProcessing.updateData(patientSeq, impressiveData.current);
  };

  // 들숨 이벤트
  const inhaleEvt = useCallback(
    (mode: ModeEnum) => {
      let res: any = null;
      let interval = DataProcessing.calcInterval(impressiveData.current); // ex -> in

      if (impressiveData.current.length === 0) {
        setIsActivatedInteraction(true);
        setUnconsciousMode(false);
        impressiveData.current.push(
          DataProcessing.procData(impressiveData.current, 1, 0)
        );
        return;
      } else if (mode === ModeEnum.CONSCIOUS && interval > 500) {
        // active inhale
        // interaction
        setIsActivatedInteraction(true);
        // passive mode off
        setUnconsciousMode(false);
        setMainTextInSession('화면을 길게 누르면서\n 숨을 깊게 들이마십니다');
        res = DataProcessing.procData(impressiveData.current, 1, 1);
      } else if (
        breathCount.current >= practice_cycle_num &&
        mode === ModeEnum.CONSCIOUS &&
        interval <= 500
      ) {
        if (unconsciousMode) {
          setUnconsciousMode(false);
        } else {
          unconsciousModeStart();
        }
        // entering passive mode
      } else {
        if (!unconsciousMode) {
          return;
        }
        // on passive mode
        // interaction
        setIsActivatedInteraction(true);
        res = DataProcessing.procData(impressiveData.current, 1, 0);
      }
      if (res === null) {
        return;
      }
      // 측정데이터 처리
      impressiveData.current.push(res);

      // 최초 시작시에는 음원 컨트롤 생략
      if (time.current === 0) {
        return;
      }

      // csv 들숨 볼륨 저장
      inhale1vol.current = csvData.current[time.current - 1].inhale1;
      inhale2vol.current = csvData.current[time.current - 1].inhale2;
      inhale3vol.current = csvData.current[time.current - 1].inhale3;

      // 들숨 음원 재생
      HowlerSound.sndPlay(
        `INHALE1_${String(session_length)}MIN`,
        inhale1vol.current
      );
      HowlerSound.sndPlay(
        `INHALE2_${String(session_length)}MIN`,
        inhale2vol.current
      );
      HowlerSound.sndPlay(
        `INHALE3_${String(session_length)}MIN`,
        inhale3vol.current
      );
      // 음원 fadeout 관련 타이머 이벤트
      HowlerSound.sndFade(
        `EXHALE1_${String(session_length)}MIN`,
        exhale1vol.current,
        2000
      );
      HowlerSound.sndFade(
        `EXHALE2_${String(session_length)}MIN`,
        exhale2vol.current,
        2000
      );
      HowlerSound.sndFade(
        `EXHALE3_${String(session_length)}MIN`,
        exhale3vol.current,
        2000
      );
    },
    [unconsciousMode, session_length]
  );

  // 날숨 이벤트
  const exhaleEvt = useCallback(
    (mode: ModeEnum) => {
      let res: any = null;
      let interval = DataProcessing.calcInterval(impressiveData.current); // in -> ex

      if (mode === ModeEnum.CONSCIOUS && interval > 500) {
        // active exhale
        // interaction
        setIsActivatedInteraction(false);
        // passive mode off
        setUnconsciousMode(false);
        //setIsPassive(false);
        setMainTextInSession('손을 떼면서\n 좀 더 천천히 내쉽니다');
        res = DataProcessing.procData(impressiveData.current, 2, 1);
      } else if (
        breathCount.current >= practice_cycle_num &&
        mode === ModeEnum.CONSCIOUS &&
        interval <= 500
      ) {
        // entering passive
        unconsciousModeStart();
      } else {
        if (!unconsciousMode) {
          setIsActivatedInteraction(false);
          return;
        }

        // on passive mode
        // interaction
        setIsActivatedInteraction(false);
        res = DataProcessing.procData(impressiveData.current, 2, 0);
      }

      if (res === null) {
        return;
      }

      // 측정데이터 처리
      impressiveData.current.push(res);

      // 최초 시작시에는 음원 컨트롤 생략
      if (time.current === 0) {
        return;
      }

      // csv 날숨 볼륨 저장
      exhale1vol.current = csvData.current[time.current - 1].exhale1;
      exhale2vol.current = csvData.current[time.current - 1].exhale2;
      exhale3vol.current = csvData.current[time.current - 1].exhale3;

      // 날숨 음원 재생
      HowlerSound.sndPlay(
        `EXHALE1_${String(session_length)}MIN`,
        exhale1vol.current
      );
      HowlerSound.sndPlay(
        `EXHALE2_${String(session_length)}MIN`,
        exhale2vol.current
      );
      HowlerSound.sndPlay(
        `EXHALE3_${String(session_length)}MIN`,
        exhale3vol.current
      );
      // 음원 fadeout 관련 타이머 이벤트
      HowlerSound.sndFade(
        `INHALE1_${String(session_length)}MIN`,
        inhale1vol.current,
        2000
      );
      HowlerSound.sndFade(
        `INHALE2_${String(session_length)}MIN`,
        inhale2vol.current,
        2000
      );
      HowlerSound.sndFade(
        `INHALE3_${String(session_length)}MIN`,
        inhale3vol.current,
        2000
      );
    },
    [unconsciousMode, session_length]
  );

  // passive session timer
  useEffect(() => {
    let tick: any = null;
    if (sessionEndAlertModal) {
      return;
    }
    if (unconsciousMode) {
      let timeout = 0;
      if (!inhaleUnconsciousMode) {
        inhaleEvt(ModeEnum.UNCONSCIOUS);
        firstIn.current = false;
        timeout = avgInhale.current;
      } else {
        exhaleEvt(ModeEnum.UNCONSCIOUS);
        firstEx.current = false;
        timeout = avgExhale.current;
      }
      tick = setTimeout(() => {
        setInhaleUnconsciousMode(!inhaleUnconsciousMode);
      }, timeout);
    }

    return () => {
      if (tick !== null) {
        clearTimeout(tick);
      }
    };
  }, [
    exhaleEvt,
    inhaleEvt,
    unconsciousMode,
    inhaleUnconsciousMode,
    sessionEndAlertModal,
  ]);

  return (
    <Fragment>
      <Header
        impressiveData={impressiveData.current}
        session_length={session_length}
      />
      {!isStarted ? (
        <BeforeFirstTouch onClick={handleClick} variant="h6">
          준비가 되셨다면 <br />
          화면을 한번 탭하여 시작합니다!
        </BeforeFirstTouch>
      ) : (
        <div
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onMouseDown={handleOnMouseDown}
          onMouseUp={handleOnMouseUp}
        >
          <div className="upperWrapper">
            {/* 수동/자동 상태 바 */}
            <State isPassive={unconsciousMode} />
          </div>
          <div className="App">
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <Interaction toggle={isActivatedInteraction}></Interaction>
            <StyledTypography variant="h6">
              {mainTextInSession}
            </StyledTypography>
          </div>
        </div>
      )}
      <ModalBottom
        open={sessionEndAlertModal}
        close={closeModal}
        header="테라피 종료"
        finished={true}
      >
        <EndAlertText variant="h4" style={{ fontWeight: 'bold' }}>
          수고하셨어요
        </EndAlertText>
        <EndAlertText variant="body1" style={{ marginTop: 20 }}>
          마음이 안정되었다면
        </EndAlertText>
        <EndAlertText variant="body1">
          눈을 감은 채 고요함을 느껴보세요
        </EndAlertText>
      </ModalBottom>
    </Fragment>
  );
}
