import React from 'react';
import '../assets/css/Interaction.css';

function Interaction(props: any) {
  const toggle = props.toggle;

  return (
    // 현재 div 자체에 mouseDown 이벤트를 적용했습니다.
    <div className={toggle ? 'interaction active' : 'interaction'}></div>
  );
}

export default Interaction;
